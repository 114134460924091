<template>
  <view-item title="工单查询">
    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="VIN码" name="vin" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <div class="flex mb-3 w-96 gap-7 place-content-between">
                当前剩余数据查询次数：{{ count.value }}
                <en-button type="primary" @click="$router.push('/query/top-up')" class="w-23">去充值</en-button>
              </div>

              <en-form :model="vin.form.data" :ref="setRef('form')">
                <en-form-item>
                  <div class="flex gap-4.4">
                    <span>VIN码 ：</span> <en-input v-model="vin.form.data.vin" style="width: 200px" @change="vin.form.init"></en-input>
                    <en-button @click="vin.form.init.click()" type="default" class="w-23">清空</en-button>
                    <en-button @click="vin.form.select.click()" type="primary" :disabled="vin.form.disabled" class="w-23">搜索</en-button>
                  </div>
                </en-form-item>
              </en-form>

              <p style="color: red">
                数据查询说明：1、查询车品牌信息会消耗查询次数1次。2、查询油品信息会消耗查询次数2次。(查询结果由第三方提供，仅供参考。)
              </p>
            </en-tab-pane>
            <en-tab-pane label="OE号" name="oe" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <div class="flex mb-3 w-96 gap-7 place-content-between">
                当前剩余数据查询次数：{{ count.value }}
                <en-button type="primary" @click="$router.push('/query/top-up')" class="w-23">去充值</en-button>
              </div>
              <en-form :model="oe.form.data" :ref="setRef('form')">
                <en-form-item>
                  <div class="flex gap-5">
                    <span>OE号 ：</span> <en-input v-model="oe.form.data.partNumber" style="width: 200px"></en-input>
                    <en-button @click="oe.form.init.click()" type="default" class="w-23">清空</en-button>
                    <en-button @click="oe.form.select.click()" type="primary" :disabled="oe.form.disabled" class="w-23">搜索</en-button>
                  </div>
                </en-form-item>
              </en-form>

              <p style="color: red">
                数据查询说明：1、查询车品牌信息会消耗查询次数1次。2、查询油品信息会消耗查询次数2次。(查询结果由第三方提供，仅供参考。)
              </p>

              <div v-if="oe.visible">
                <p>
                  请选择想要的查询结果
                  <span style="color: #666666; font-size: 12px">共{{ oe.table.data.length }}个结果</span>
                </p>
                <en-table :data="oe.table.data">
                  <en-table-column label="车品牌" prop="vehicleInfo[0].brand"></en-table-column>
                  <en-table-column label="原厂名称" prop="stdPartName"></en-table-column>
                  <en-table-column label="4S店参考价" prop="partPrice"></en-table-column>
                </en-table>
              </div>
            </en-tab-pane>
            <en-tab-pane label="车品牌" name="goods" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <div class="flex mb-3 w-96 gap-7 place-content-between">
                当前剩余数据查询次数：{{ count.value }}
                <en-button type="primary" @click="$router.push('/query/top-up')" class="w-23">去充值</en-button>
              </div>
              <en-form :model="vehicle.form.data" :ref="setRef('form')">
                <en-form-item>
                  <div class="flex gap-4.4">
                    <span>VIN码 ：</span> <en-input v-model="vehicle.form.data.vin" style="width: 200px"></en-input>
                    <en-button @click="vehicle.form.init.click()" type="default" class="w-23">清空</en-button>
                    <en-button @click="vehicle.form.select.click()" type="primary" :disabled="vehicle.form.disabled" class="w-23">搜索</en-button>
                  </div>
                </en-form-item>
              </en-form>

              <p style="color: red">
                数据查询说明：1、查询车品牌信息会消耗查询次数1次。2、查询油品信息会消耗查询次数2次。(查询结果由第三方提供，仅供参考。)
              </p>
              <div v-if="vehicle.table.data.length !== 0">
                <span>车品牌信息：</span>
                <div style="margin-left: 100px" class="grid grid-cols-3 gap-4">
                  <div>
                    <en-form-item label="品牌">
                      {{ vehicle.table.data.Brand }}
                    </en-form-item>

                    <en-form-item label="厂家">
                      {{ vehicle.table.data.Manufacturers }}
                    </en-form-item>

                    <en-form-item label="车系">
                      {{ vehicle.table.data.Series }}
                    </en-form-item>

                    <en-form-item label="车品牌">
                      {{ vehicle.table.data.Models }}
                    </en-form-item>

                    <en-form-item label="车身型式">
                      {{ vehicle.table.data.BodyType }}
                    </en-form-item>

                    <en-form-item label="国别">
                      {{ vehicle.table.data.Country }}
                    </en-form-item>

                    <en-form-item label="排量（L）">
                      {{ vehicle.table.data.Displacement }}
                    </en-form-item>

                    <en-form-item label="排量（带T）">
                      {{ vehicle.table.data.DisplacementWithT }}
                    </en-form-item>

                    <en-form-item label="驱动方式">
                      {{ vehicle.table.data.DriveMode }}
                    </en-form-item>

                    <en-form-item label="驱动形式">
                      {{ vehicle.table.data.DriveModel }}
                    </en-form-item>

                    <en-form-item label="排放标准">
                      {{ vehicle.table.data.EmissionStandard }}
                    </en-form-item>

                    <en-form-item label="发动机描述">
                      {{ vehicle.table.data.EngineDescription }}
                    </en-form-item>

                    <en-form-item label="发动机生产厂家">
                      {{ vehicle.table.data.EngineManufacturer }}
                    </en-form-item>

                    <en-form-item label="发动机型号">
                      {{ vehicle.table.data.EngineModel }}
                    </en-form-item>

                    <en-form-item label="前轮胎规格">
                      {{ vehicle.table.data.FrontTyre }}
                    </en-form-item>

                    <en-form-item label="燃油标号">
                      {{ vehicle.table.data.FuelGrade }}
                    </en-form-item>

                    <en-form-item label="供油方式">
                      {{ vehicle.table.data.FuelInjection }}
                    </en-form-item>

                    <en-form-item label="燃油类型">
                      {{ vehicle.table.data.FuelType }}
                    </en-form-item>

                    <en-form-item label="档位数">
                      {{ vehicle.table.data.GearNumber }}
                    </en-form-item>
                  </div>
                  <div>
                    <en-form-item label="代数">
                      {{ vehicle.table.data.Generation }}
                    </en-form-item>

                    <en-form-item label="指导价格（万元）">
                      {{ vehicle.table.data.GuidingPrice }}
                    </en-form-item>

                    <en-form-item label="高度（mm）">
                      {{ vehicle.table.data.Height }}
                    </en-form-item>

                    <en-form-item label="长度（mm）">
                      {{ vehicle.table.data.Length }}
                    </en-form-item>

                    <en-form-item label="上市年份">
                      {{ vehicle.table.data.ListingYear }}
                    </en-form-item>

                    <en-form-item label="后轮胎规格">
                      {{ vehicle.table.data.RearTyre }}
                    </en-form-item>

                    <en-form-item label="销售名称">
                      {{ vehicle.table.data.SalesName }}
                    </en-form-item>

                    <en-form-item label="销售状态">
                      {{ vehicle.table.data.SalesStatus }}
                    </en-form-item>

                    <en-form-item label="销售版本">
                      {{ vehicle.table.data.SalesVersion }}
                    </en-form-item>

                    <en-form-item label="变速器描述">
                      {{ vehicle.table.data.TransmissionDescription }}
                    </en-form-item>

                    <en-form-item label="变速器类型">
                      {{ vehicle.table.data.TransmissionType }}
                    </en-form-item>

                    <en-form-item label="厂家类型（国产,合资,进口）">
                      {{ vehicle.table.data.VehicleAttributes }}
                    </en-form-item>

                    <en-form-item label="车身颜色">
                      {{ vehicle.table.data.VehicleColor }}
                    </en-form-item>

                    <en-form-item label="车辆级别">
                      {{ vehicle.table.data.VehicleSize }}
                    </en-form-item>

                    <en-form-item label="车辆类型">
                      {{ vehicle.table.data.VehicleType }}
                    </en-form-item>

                    <en-form-item label="轴距（mm）">
                      {{ vehicle.table.data.Wheelbase }}
                    </en-form-item>

                    <en-form-item label="宽度（mm）">
                      {{ vehicle.table.data.Width }}
                    </en-form-item>

                    <en-form-item label="年款">
                      {{ vehicle.table.data.Year }}
                    </en-form-item>
                  </div>
                </div>
              </div>
            </en-tab-pane>
            <en-tab-pane label="油品" name="oil" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <div class="flex mb-3 w-96 gap-7 place-content-between">
                当前剩余数据查询次数：{{ count.value }}
                <en-button type="primary" @click="$router.push('/query/top-up')" class="w-23">去充值</en-button>
              </div>
              <en-form :model="oil.form.data" :ref="setRef('form')">
                <en-form-item>
                  <div class="flex gap-4.4">
                    <span>VIN码 ：</span> <en-input v-model="oil.form.data.vin" style="width: 200px"></en-input>
                    <en-button @click="oil.form.init.click()" type="default" class="w-23">清空</en-button>
                    <en-button @click="oil.form.select.click()" type="primary" :disabled="oil.form.disabled" class="w-23">搜索</en-button>
                  </div>
                </en-form-item>
              </en-form>

              <p style="color: red">
                数据查询说明：1、查询车品牌信息会消耗查询次数1次。2、查询油品信息会消耗查询次数2次。(查询结果由第三方提供，仅供参考。)
              </p>
              <div v-if="oil.visible">
                <en-table :data="oil.table.data">
                  <en-table-column label="油品类型" prop="ProductCategoryName"></en-table-column>
                  <en-table-column label="保养加注量" prop="Refilled"></en-table-column>
                  <en-table-column label="基础油类型" prop="Ranges"></en-table-column>
                  <en-table-column label="级别" prop="Grade"></en-table-column>
                  <en-table-column label="规格" prop="Specification"></en-table-column>
                  <en-table-column label="大修加注量" prop="InitialFill"></en-table-column>
                  <en-table-column label="加注量" prop="FillingVolume"></en-table-column>
                  <en-table-column label="循环加注量" prop="CyclicFilling"></en-table-column>
                </en-table>
              </div>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>
  </view-item>
  <en-dialog v-model="select.visible" title="常用配件查询" style="width: 85%; height: 85%">
    <div class="query-data-vin">
      <div class="query-data-vin__headerline">VIN码： {{ vin.form.data.vin }}</div>

      <div class="query-data-vin__headerline">车品牌：{{ vin.form.data.subBrand }}</div>

      <div class="query-data-vin__headerline">车系：{{ vin.form.data.vehicleSys }}</div>

      <div class="query-data-vin__headerline">年份：{{ vin.form.data.year }}</div>

      <div class="query-data-vin__headerline">排量/发动机：{{ vin.form.data.engine }}</div>
      <div class="query-data-vin__headerline">变速箱：{{ vin.form.data.transmission }}</div>
    </div>
    <en-form :model="vinDialog.form.data" :ref="setRef('form')" style="margin-top: 20px">
      <en-form-item>
        <en-input v-model="vinDialog.form.data.key" style="width: 200px"></en-input>
        <en-button @click="vinDialog.form.commonParts.clickQuery" type="primary">搜索</en-button>
        <!-- <en-button @click="vinDialog.form.select.click()" style="margin-left: 30px">结构树状查询</en-button> -->
      </en-form-item>
    </en-form>
    <div v-for="item of vinDialog.items.data" class="drp-inquiry-detail-commonParts">
      <span style="margin-right: 10px">{{ item.type }}</span>
      <span v-for="i of item.children" class="px-1 cursor-pointer" @click="vinDialog.items.item.click(i)">{{ i }}</span>
    </div>
    <en-collapse >
      <en-collapse-item title="查询结果（展开查看）">
        <en-table :data="vinDialog.table.data">
          <en-table-column label="配件名称" prop="srcPartName"> </en-table-column>
          <en-table-column label="OE号" prop="partNumber"> </en-table-column>
          <en-table-column label="4S店参考价" prop="partPrice"> </en-table-column>
        </en-table> </en-collapse-item
    ></en-collapse>
  </en-dialog>
</template>

<script lang="ts">

export default factory({

  config: {
    children: {
      select: {
        visible: false
      },
      form: {
        data: {
          drawer: false,
          vin: '',
          partNumber: '',
          key: '',
          matchway: '1' // 模糊和精确
        }
      },
      count: {
        form: {
          data: {
            drawer: false,
            vin: '',
            partNumber: '',
            key: '',
            matchway: '1' // 模糊和精确
          }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/query/vehicle/count',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.count.form.data
            }
          }
        }
      },
      vin: {
        children: {
          form: {
            data: {
              drawer: false,
              vin: '',
              partNumber: '',
              key: '',
              matchway: '1' // 模糊和精确
            },
            init: {
              click() {
                return (this.vin.form.data.vin = '')
              }
            },
            select: {
              click() {
                this.count.get().then((res) => {
                  this.vin.table.get().then(() => {
                    sessionStorage.clear()
                    let data = this.vin.table.data2()
                    this.vin.form.data = Object.assign(this.vin.form.data, {
                      brand: data[0].brands[0],
                      subBrand: data[0].subBrands[0],
                      vehicleSys: data[0].vehicleSyss[0],
                      vehicleGroup: data[0].vehicleGroups[0],
                      prefix: data[0].prefixs[0]
                    })
                    if (data[0].years.length > 1 || data[0].engines.length > 1 || data[0].transmissions.length > 1) {
                      this.vin.visible = true
                    } else {
                      this.vin.form.data = Object.assign(this.vin.form.data, {
                        year: data[0].years[0],
                        engine: data[0].engines[0],
                        transmission: data[0].transmissions[0]
                      })

                      this.count.get().then((res) => (this.count.value = res.data[0]))
                    }
                    this.select.visible = true
                  })
                })
              }
            },
            computed: {
              disabled() {
                return this.vin.form.data.vin === ''
              }
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enoquote/client/mingjue/vehicle/:vin',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.vin.form.data.vin]
                }
              }
            },
            data2() {
              return [...new Set(this.vin.table.data.map((item) => item.vin))].map((item) => {
                const arr = this.vin.table.data.filter((d) => d.vin === item)
                const deduplicate = (key) => [...new Set(arr.map((a) => a[key]))]
                return {
                  vin: item,
                  brands: deduplicate('brand'),
                  subBrands: deduplicate('subBrand'),
                  vehicleSyss: deduplicate('vehicleSys'),
                  vehicleGroups: deduplicate('vehicleGroup'),
                  prefixs: deduplicate('prefix'),
                  years: deduplicate('year'),
                  engines: [...new Set(arr.map((a) => `${a.displacement}|${a.engine}`))],
                  transmissions: deduplicate('transmission')
                }
              })
            },
            year: {
              change(row: any) {
                this.vin.table.currentRow = row
                this.vin.form.data.engine = ''
                this.vin.form.data.transmission = ''
                if (this.vin.table.engine.options(row).length === 1) this.vin.form.data.engine = this.vin.table.engine.options(row)[0]
                if (this.vin.table.transmission.options(row).length === 1)
                  this.vin.form.data.transmission = this.vin.table.transmission.options(row)[0]
              }
            },
            engine: {
              options(row: any) {
                let arr = this.vin.table.data.filter((d) => d.vin === row.vin)
                if (this.vin.form.data.year.length) arr = arr.filter((item) => item.year === this.vin.form.data.year)
                if (this.vin.table.transmission.options(row).length === 1)
                  this.vin.form.data.transmission = this.vin.table.transmission.options(row)[0]
                return this.vin.form.data.year.length ? [...new Set(arr.map((a) => `${a.displacement}|${a.engine}`))] : row.engines
              }
            },
            transmission: {
              options(row: any) {
                let arr = this.vin.table.data.filter((d) => d.vin === row.vin)
                if (this.vin.form.data.year.length) arr = arr.filter((item) => item.year === this.vin.form.data.year)
                if (this.vin.form.data.engine.length) {
                  let [displacement, engine] = this.vin.form.data.engine.split('|')
                  arr = arr.filter((item) => item.displacement === displacement && item.engine === engine)
                }
                return this.vin.form.data.year.length && this.vin.form.data.engine.length
                  ? [...new Set(arr.map((a) => a.transmission))]
                  : row.transmissions
              }
            }
          }
        }
      },
      vinDialog: {
        children: {
          form: {
            data: {
              key: '',
              drawer: false,
              vinCode: '',

            },
            commonParts: {
              return(inner: any) {
                this.vinDialog.form.data.key = inner
                this.vinDialog.table.get()
              },
              clickQuery() {
    
                this.vinDialog.form.data.vinCode = this.vin.form.data.vin
                this.vinDialog.table.get()
              }
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enoquote/client/mingjue/part',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.vinDialog.form.data
                },
                convert(data: any) {
                  data.vinCode = this.vinDialog.form.key
                  return data
                }
              }
            }
          },
          items: {
            data: [
              {
                type: '前外',
                children: ['中网', '前保险杠', '前杠骨架', '发动机盖', '前挡风玻璃', '前风挡雨刮臂', '前大灯', '前翼子板', '前钢圈']
              },
              {
                type: '前内',
                children: [
                  '喇叭',
                  '散热器',
                  '冷凝器',
                  '空气滤清器',
                  '机油滤清器',
                  '蓄电池',
                  '发电机',
                  '起动机',
                  '前桥',
                  '转向机',
                  '前平衡杆',
                  '前转向节',
                  '前上摆臂',
                  '前下摆臂',
                  '前减震器'
                ]
              },
              {
                type: '中外',
                children: ['倒车镜', '前车门', '后车门', '侧围']
              },
              {
                type: '中内',
                children: ['仪表台', '气囊电脑', '气囊卷簧', '组合开关', '驾驶员安全气囊', '副驾驶安全气囊', '驾驶员安全带', '副驾驶安全带']
              },
              {
                type: '后外',
                children: ['后杠', '行李箱盖', '后徽标', '后挡风玻璃', '后翼子板', '后尾灯', '后钢圈']
              },
              {
                type: '后内',
                children: ['排气管后段', '后转向节', '后减震器', '后下摆臂']
              }
            ],
            item: {
              click(i: string) {
        
                this.vinDialog.form.data.key = i
             
                this.vinDialog.form.data.vinCode = this.vin.form.data.vin
                this.vinDialog.table.get()
              }
            }
          }
        }
      },
      vehicle: {
        children: {
          form: {
            is: 'form',
            data: {
              drawer: false,
              vin: '',
              partNumber: '',
              matchway: '1' // 模糊和精确
            },
            init: {
              click() {
                return (this.vehicle.form.data.vin = '')
              }
            },
            select: {
              async click() {
                await this.vehicle.table.count()
                this.vehicle.table.get()
              }
            },
            computed: {
              disabled() {
                return this.vehicle.form.data.vin === ''
              }
            }
          },

          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/query/vehicle/info/vehicle',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.vehicle.form.data
                },
                convert(data: any) {
                  return JSON.parse(data)
                }
              },
              count: {
                action: 'GET /enocloud/query/vehicle/count',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.vehicle.form.data
                }
              }
            }
          }
        }
      },
      oe: {
        children: {
          form: {
            data: {
              drawer: false,
              vin: '',
              partNumber: '',
              matchway: '1'
            },
            select: {
              async click() {
                this.oe.table.get().then((res) => {
                  this.oe.visible = true
                  this.count.get().then((res) => (this.count.value = res.data.data[0]))
                })
              }
            },
            init: {
              click() {
                return (this.oe.form.data.partNumber = '')
              }
            },
            computed: {
              disabled() {
                return this.oe.form.data.partNumber === ''
              }
            }
          },

          table: {
            ajax: {
              get: {
                action: 'GET /enoquote/client/mingjue/oe/cleaningall',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.oe.form.data
                }
              }
            }
          }
        }
      },
      oil: {
        children: {
          form: {
            is: 'form',
            data: {
              drawer: false,
              vin: '',
              partNumber: '',
              matchway: '1' // 模糊和精确
            },
            init:{
              click(){
                return this.oil.form.data.vin=''
              }
            },
            select: {
              async click() {
                this.oil.table.get().then((res) => {
                  this.oil.visible = true
                  this.count.get().then((res) => (this.count.value = res.data.data[0]))
                })
              }
            },
            computed: {
              disabled() {
                return this.oil.form.data.vin === ''
              }
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/query/vehicle/info/oil',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.oil.form.data
                },
                convert(data: any) {
                  return JSON.parse(data)
                }
              }
            }
          }
        }
      },
      tabs: {
        active: 'vin'
      }
    }
  },

  mounted() {
    this.count.get().then((res) => (this.count.value = res.data[0]))
  }
})
</script>
<style scoped lang="scss">
.query-data-vin__headerline {
  color: #000;

  margin-right: 40px;
}

.query-data-vin {
  display: flex;
}

.drp-inquiry-detail-commonParts {
  height: 30px;
  line-height: 30px;
  font-size: 13px;

  span:hover {
    color: #f4523b;
  }

  span:first-child {
    color: #cecece;

    &:hover {
      color: #cecece;
    }
  }
}
</style>
